// Breakpoints
$x-small: 480px;
$small: 768px;
$medium: 900px;
$large: 1080px;

$sidebar-width: 90px;
$menu-height: 68px;

$form-element-height-sm: 38px;
$form-element-height: 42px;
$form-element-height-lg: 45px;

// Colors
$primary: #111827;
$primary-selected: #1f2937;
$primary-hover: #435164;
$primary-inverted: #9299a5;
$white: #ffffff;
$sidebar-gray: #eee;
$background-gray: #f1f1f1;
$gray: #454545;
$medium-gray: #7c7c7c;
$border-color: #f9f9f9;
$accent: #4b93e7;
$accent-90: #5d9de9;
$accent-80: #6fa8ec;
$accent-70: #81b3ee;
$accent-60: #93bef1;
$accent-50: #a5c9f3;
$accent-40: #b7d4f5;
$accent-30: #c9def8;
$accent-20: #dbe9fa;
$accent-10: #edf4fd;

$secondary: #ffffff;
$secondary-hover: #eaeaea;
$secondary-inverted: #111827;
// Accent
$accent: #222222;
$accent-selected: #000000;
$accent-hover: #000000;
$hover: rgba(0, 0, 0, 0.04);

// Forms
$input-border: #dddddd;

// States
$info: #2196f3;
$error: #f44336;
$success: #4caf50;
$warning: #ff9800;
$disabled-bg: #f7f7f7;

// Defaults
$border-radius: 6px;
$border-light: #dfdddd;
$border-medium: #a3a3a3;
$border-dark: #6b6b6b;

// Shadows
$shadow-1: 0 2px 4px rgba(0, 0, 0, 0.1);
$shadow-2: 0 0 10px rgba(0, 0, 0, 0.08), 0 0 20px rgba(0, 0, 0, 0.12);
$shadow-3: 0 2px 4px rgba(0, 0, 0, 0.08);
$shadow-inset-1: inset -2px 4px 5px rgba(0, 0, 0, 0.05);

// Transitions
$transition-1: 0.15s linear;

// Structurals
$sidebar-logo-height: 68px;

// Media queries
@mixin tablet-up {
  @media only screen and (min-width: #{$small + 1}) {
    @content;
  }
}
@mixin md-down {
  @media only screen and (max-width: $medium) {
    @content;
  }
}
@mixin sm-down {
  @media only screen and (max-width: $small) {
    @content;
  }
}
@mixin tablet {
  @media only screen and (max-width: $large) {
    @content;
  }
}
@mixin mobile {
  @media only screen and (max-width: $small) {
    @content;
  }
}
@mixin desktop {
  @media only screen and (min-width: $large) {
    @content;
  }
}

@mixin lg {
  @media only screen and (min-width: #{$medium}) {
    @content;
  }
}
@mixin md {
  @media only screen and (min-width: #{$small + 1}) {
    @content;
  }
}
@mixin sm {
  @media only screen and (min-width: #{$x-small + 1}) {
    @content;
  }
}
@mixin xs {
  @media only screen and (max-width: $x-small) {
    @content;
  }
}

@mixin small-text {
  font-size: 0.9rem;
}

@mixin hover {
  &:hover {
    transition: 0.2s linear;
    opacity: 0.6;
    cursor: pointer;
  }
}

@mixin buttonTheme {
  &:hover {
    cursor: pointer;
  }

  &.primary {
    background-color: $accent;
    color: $white;

    &:hover {
      background-color: $accent-hover;
    }
  }
  &.success {
    background-color: $success;
    color: $white;

    &:hover {
      background-color: darken($success, 5%);
    }
  }
  &.secondary {
    background-color: $secondary;
    color: $primary;
    box-sizing: border-box;
    border: 1px solid $border-light;

    &:hover {
      background-color: $secondary-hover;
    }
  }
  &.outline {
    background-color: $secondary;
    color: $primary;
    box-sizing: border-box;
    border: 1px solid $border-light;

    &:hover {
      background-color: $secondary-hover;
    }
  }
  &.tertiary {
    background-color: $white;
    color: $accent;
    box-sizing: border-box;

    &:hover {
      background-color: #{$accent}20;
    }
  }
  &.accent {
    background-color: $accent;
    color: $white;
  }
  &.loading {
    pointer-events: none;
    position: absolute;
    z-index: 3;
    backdrop-filter: blur(4px);
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    width: calc(100%);
    height: calc(100%);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
  }
  &.loading .loading-preloader {
    animation: spinning 1s linear infinite;
  }
  &.delete {
    background-color: $error;
    color: $white;
    border-color: $error;

    &:hover:not(:active) {
      background-color: darken($error, 10%);
    }
    &:active {
      background-color: darken($error, 0%);
    }
  }

  &:not(.loading):disabled {
    color: $disabled-bg;
    bf-icon {
      color: $border-medium !important;
    }
    background: $border-light;
    cursor: not-allowed;
  }
}

@mixin readOnly {
  &:not(mat-select) {
    margin-top: -6px;
  }
  border: 0;
  padding: 0;
  @include small-text;
  font-weight: 400;
}

@mixin inputMixin {
  box-sizing: border-box;
  font-size: 1rem;
  padding: 8px;
  border-radius: $border-radius;
  background-color: $white;

  &::placeholder {
    font-size: 1rem;
  }

  &.no-border {
    border-color: transparent;
    padding: 0;
    height: auto;
  }

  &.sm {
    height: $form-element-height-sm;
    padding: 6px;
  }
  &.lg {
    height: $form-element-height-lg;
    padding: 10px;
  }

  &:focus {
    outline-color: $accent;
    outline-offset: 4px;
  }

  &:disabled {
    border: 2px solid $border-light;
    border-radius: $border-radius;
    color: rgba(0, 0, 0, 0.4);
    background-color: $disabled-bg;
    &::placeholder {
      opacity: 0.4;
    }
  }

  &:not(:disabled) {
    &.bf-read-only,
    &:read-only {
      @include readOnly;
    }
  }

  &:not(:read-only) {
    border: 1px solid $input-border;
    border-radius: $border-radius;

    &.icon-left {
      padding-left: 40px;
    }

    &.invalid {
      border-color: $error;
    }
  }
}

.hide-on-mobile {
  @include sm-down {
    display: none;
  }
}

.hide-on-tablet-up {
  @include tablet-up() {
    display: none;
  }
}
