@use "variables" as var;

.mat-mdc-slide-toggle {
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-selected-handle-color: var(--accent-hover);
  --mdc-switch-selected-focus-state-layer-color: var(--accent) !important;
  --mdc-switch-selected-handle-color: var(--accent) !important;
  --mdc-switch-selected-hover-state-layer-color: var(--accent) !important;
  --mdc-switch-selected-pressed-state-layer-color: var(--accent) !important;
  --mdc-switch-selected-focus-handle-color: var(--accent) !important;
  --mdc-switch-selected-hover-handle-color: var(--accent) !important;
  --mdc-switch-selected-pressed-handle-color: var(--accent) !important;
  --mdc-switch-selected-focus-track-color: var(--accent-hover) !important;
  --mdc-switch-selected-hover-track-color: var(--accent-hover) !important;
  --mdc-switch-selected-pressed-track-color: var(--accent-hover) !important;
  --mdc-switch-selected-track-color: var(--accent-hover) !important;
}

.mat-mdc-select-value {
  font-size: 1rem !important;
  color: #000 !important;
  font-family: "Inter", sans-serif;
}

.mat-expansion-panel {
  border: 1px solid lighten(var.$border-light, 3%);
  box-shadow: var.$shadow-3 !important;
}
