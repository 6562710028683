@use "sass:math";
@import "variables.scss";

$grid-gutter: 24px;
$step-size: 4px;
$grid-cols: 12;

.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}

.border-t {
  border-top: 1px solid $input-border;
}

.sticky {
  position: sticky;
}
.bottom-0 {
  bottom: 0;
}
.bg-white {
  background-color: $white;
}

.d-inline-block {
  display: inline-block;
}
.d-block {
  display: block;
}
.p-relative {
  position: relative;
}
.container {
  padding: 0 math.div($grid-gutter, 2);
}

.content-medium {
  max-width: 900px;
  margin: 0 auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 math.div(-$grid-gutter, 2);
  &.no-gutter {
    margin: 0;
  }
}

/** col()
*   A function that will iterate from one to $grid-cols
*   to generate a grid system based on a size.
*/
@mixin col($size) {
  @for $i from 1 through $grid-cols {
    .col#{$size}-#{$i} {
      margin: 0 math.div($grid-gutter, 2);
      flex: 0 0 auto;
      vertical-align: top;
      width: calc(#{math.div($i, 12) * 100%} - #{$grid-gutter});
    }
  }
}

@media (min-width: 0px) {
  @include col("-xs");
}
@media (min-width: #{$x-small + 1}) {
  @include col("-sm");
}
@media (min-width: #{$small + 1}) {
  @include col("-md");
}
@media (min-width: #{$medium + 1}) {
  @include col("-lg");
}
@media (min-width: #{$large + 1}) {
  @include col("-xl");
}

/** Will make it possible to have fixes sizes for all screens */
@include col("");

/** margins()
*   Will make it possible to generate helper classes to add
*   margins to elements in accordance to the grid-system.
*   An example usage could be mt-1 that would add margin-top: 4px;
*/
@mixin margins($size) {
  @for $i from 0 through 8 {
    .m#{$size}-#{$i} {
      margin: #{$step-size * $i};
    }
    .mr#{$size}-#{$i} {
      margin-right: #{$step-size * $i};
    }
    .ml#{$size}-#{$i} {
      margin-left: #{$step-size * $i};
    }
    .mb#{$size}-#{$i} {
      margin-bottom: #{$step-size * $i};
    }
    .mt#{$size}-#{$i} {
      margin-top: #{$step-size * $i};
    }
    .my#{$size}-#{$i} {
      margin-top: #{$step-size * $i};
      margin-bottom: #{$step-size * $i};
    }
    .mx#{$size}-#{$i} {
      margin-left: #{$step-size * $i};
      margin-right: #{$step-size * $i};
    }
    .mx#{$size}-auto {
      margin: 0 auto;
    }
  }
}
/** paddings()
*   Will make it possible to generate helper classes to add
*   margins to elements in accordance to the grid-system.
*   An example usage could be pt-1 that would add padding-top: 4px;
*/
@mixin paddings($size) {
  @for $i from 0 through 8 {
    .p#{$size}-#{$i} {
      padding: #{4 * $i}px;
    }
    .pr#{$size}-#{$i} {
      padding-right: #{$step-size * $i};
    }
    .pl#{$size}-#{$i} {
      padding-left: #{$step-size * $i};
    }
    .pb#{$size}-#{$i} {
      padding-bottom: #{$step-size * $i};
    }
    .pt#{$size}-#{$i} {
      padding-top: #{$step-size * $i};
    }
    .px#{$size}-#{$i} {
      padding-left: #{$step-size * $i};
      padding-right: #{$step-size * $i};
    }
    .py#{$size}-#{$i} {
      padding-top: #{$step-size * $i};
      padding-bottom: #{$step-size * $i};
    }
  }
}

@mixin gaps($size) {
  @for $i from 0 through 8 {
    .gap#{$size}-#{$i} {
      gap: #{$step-size * $i};
    }
  }
}

@include margins("");
@include paddings("");
@include gaps("");

@media (max-width: #{$medium + 1}) {
  @include gaps("-lg");
  @include margins("-lg");
  @include paddings("-lg");
}

@media (max-width: #{$small + 1}) {
  @include gaps("-md");
  @include margins("-md");
  @include paddings("-md");
}

@media (max-width: #{$x-small + 1}) {
  @include gaps("-sm");
  @include margins("-sm");
  @include paddings("-sm");
}

@include mobile {
  .hide-on-mob {
    display: none !important;
  }
}

@include mobile {
  .hide-on-mob {
    display: none !important;
  }
}

.show-on-mob {
  display: none !important;

  @include mobile {
    display: block !important;
  }
}

.show-on-tablet-up {
  display: none !important;

  @include lg {
    display: block !important;
  }
}

.show-on-md-down {
  display: none !important;

  @include md-down {
    display: block !important;
  }
}

.hide-on-md-down {
  display: block !important;

  @include md-down {
    display: none !important;
  }
}

.hide-on-sm-down {
  display: block !important;

  @include sm-down {
    display: none !important;
  }
}

.show-on-sm-down {
  display: none !important;

  @include sm-down {
    display: block !important;
  }
}

.mx-auto {
  margin: auto auto;
}

.d-inline-flex,
.flex {
  display: flex;
  &.flex-col {
    flex-direction: column;
  }
  &.justify-between {
    justify-content: space-between;
  }
  &.justify-end {
    justify-content: flex-end;
  }
  &.justify-center {
    justify-content: center;
  }
  &.items-center {
    align-items: center;
  }
  &.items-end {
    align-items: end;
  }
  &.items-start {
    align-items: start;
  }
  &.wrap {
    flex-wrap: wrap;
  }
  .grow-1 {
    flex-grow: 1;
  }
  .grow-0 {
    flex-grow: 0;
  }
  .shrink-1 {
    flex-shrink: 1;
  }
  .shrink-0 {
    flex-shrink: 0;
  }
  .order-1 {
    order: 1;
  }
  .order-2 {
    order: 2;
  }
  .order-3 {
    order: 3;
  }

  @include mobile {
    .order-sm-1 {
      order: 1;
    }
    .order-sm-2 {
      order: 2;
    }
    .order-sm-3 {
      order: 3;
    }
  }
}

.d-inline-flex {
  display: inline-flex;
}

.d-inline-block {
  display: inline;
}

.grid {
  display: grid;
  box-sizing: border-box;

  &.grid-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  &.grid-3 {
    grid-template-columns: repeat(3, 1fr);
  }
  &.grid-4 {
    grid-template-columns: repeat(4, 1fr);
  }
}
