@import "@angular/material/prebuilt-themes/azure-blue.css";
@import "ngx-toastr/toastr";
@import "variables.scss";
@import "typography.scss";
@import "grid.scss";
@import "material.scss";
@import "../node_modules/quill/dist/quill.snow.css";

:root {
  --accent: #3f51b5;
  --accent-hover: #5c6bc0;
  --mat-menu-container-color: #fff;
  --mat-paginator-container-background-color: #fff;
  --mat-stepper-container-color: #fff;
  --mdc-dialog-container-shape: 4px;
  --mdc-dialog-container-color: #fff;

  --bg: #f7f7f7;

  .mat-accent.mat-mdc-radio-button {
    --mdc-radio-selected-focus-icon-color: #222;
    --mdc-radio-selected-hover-icon-color: #222;
    --mdc-radio-selected-icon-color: #222;
    --mdc-radio-selected-pressed-icon-color: #222;
    --mat-radio-checked-ripple-color: #222;
  }

  .mat-expansion-panel {
    --mat-expansion-container-background-color: #ffffff;
    --mat-expansion-container-shape: 6px;
  }
}

body {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  background-color: $white;
  margin: 0;

  @include mobile {
    background-color: $background-gray;
  }
}

html,
body {
  height: 100%;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 300, "GRAD" -25, "opsz" 20;
}

.flex {
  display: flex;

  &.flex-row {
    flex-direction: row;
  }
  &.flex-col {
    flex-direction: column;
  }
  &.justify-between {
    justify-content: space-between;
  }
  &.items-center {
    align-items: center;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.mat-vertical-content {
  padding: 24px !important;
}

.horizontal-divider {
  width: 100%;
  border-bottom: 1px solid $input-border;
}

.bf-link {
  color: var(--accent);
  text-decoration: none;
  cursor: pointer;
  transition: color 0.2s;
  font-weight: 500;

  &:focus {
    outline-color: $accent;
    outline-offset: 4px;
  }

  &:hover {
    color: var(--accent-hover);
    text-decoration: underline;
  }
}

.cdk-overlay-pane.mat-mdc-dialog-panel {
  max-width: min-content !important;
}

.mdc-switch--selected .mdc-switch__icon {
  fill: white !important;
}

.bold {
  font-weight: 500;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
