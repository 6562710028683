@import "variables";

.heading-1 {
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 16px;
  font-weight: 500;
  color: $primary;
  letter-spacing: 0.02em;
}
.subheading {
  color: $medium-gray;
  font-weight: 300;
  margin-top: -10px;
  font-size: 1.1rem;
  margin-bottom: 20px;
  padding-bottom: 20px;

  &.small {
    font-size: 0.9rem;
  }
  &:not(.no-border) {
    border-bottom: 1px solid $border-light;
  }
}
.heading-2 {
  color: $primary;
  font-size: 1.9rem;
  margin-top: 16px;
  margin-bottom: 16px;
  font-weight: 500;
  letter-spacing: 0.02em;
}
.heading-3 {
  color: $primary;
  font-size: 1.3rem;
  margin-top: 16px;
  margin-bottom: 16px;
  font-weight: 500;
}
.heading-4 {
  color: $primary;
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 16px;
  font-weight: 400;
}

.medium-text {
  color: $border-medium;
  font-size: 1rem;
  font-weight: 400;
}

.small-text {
  @include small-text;
}

.body1 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
}

.body2 {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.2;
}
